import React, {useState} from 'react';
import {Link} from 'gatsby';
import {arrayOf, string, object, bool, shape} from 'prop-types';
import Chevron from '../img/chevron-ikon.svg';
import {trackButtonClick} from '../util/trackButtonClick';

const NavigationList = ({items}) => {
    const [expandedItemId, setExpandedItemId] = useState(null);
    const shouldExpandSection = item => item.id === expandedItemId || item.isParentOfCurrentPath;
    const createClickHandler = item => () => {
        setExpandedItemId(expandedItemId === item.id ? null : item.id);
        trackButtonClick({action: 'nav-main-link', text: item.title});
    };

    return (
        <ul className="sb1ds-nav__list">
            {items.map(item => item.children ?
                <li key={item.id} className="sb1ds-nav__list-item">
                    <button
                        className={`sb1ds-nav__list-btn ${shouldExpandSection(item) ? '' : 'sb1ds-nav__list-btn--closed'}`}
                        onClick={createClickHandler(item)}
                        type="button">
                        <span className="sb2ds-nav__list-btn-label">{item.title}</span>
                        <Chevron className="sb1ds-nav__toggle "/>
                    </button>
                    {shouldExpandSection(item) &&
                        <ul className="sb1ds-nav__list sb1ds-nav__list--nested">
                            {item.children.map(child =>
                                <li key={child.id} className="sb1ds-nav__list-item">
                                    <Link
                                        to={child.path}
                                        className="sb1ds-nav__list-link"
                                        activeClassName="sb1ds-nav__list-link--active"
                                        onClick={() => trackButtonClick({action: 'nav-sub-link', text: child.title})}
                                    >
                                        {child.title}
                                    </Link>
                                </li>
                            )}
                        </ul>
                    }
                </li>
                :
                <li key={item.id} className="sb1ds-nav__list-item">
                    <Link
                        to={item.path}
                        className="sb1ds-nav__list-link"
                        activeClassName="sb1ds-nav__list-link--active"
                        onClick={() => trackButtonClick({action: 'nav-main-link', text: item.title})}
                    >
                        {item.title}
                    </Link>
                </li>
            )}
            <li className="sb1ds-nav__list-item">
                <Link
                    to="https://sparebank1.github.io/designsystem"
                    className="sb1ds-nav__list-link"
                    activeClassName="sb1ds-nav__list-link--active"
                    onClick={() => trackButtonClick({action: 'nav-main-link', text: "Komponenter"})}
                >
                    Komponenter
                </Link>
            </li>
        </ul>);
};

NavigationList.propTypes = {
    items: arrayOf(shape({
        id: string.isRequired,
        title: string.isRequired,
        path: string,
        isParentOfCurrentPath: bool,
        children: arrayOf(object)
    })).isRequired
};

export default NavigationList;
